import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ArrowDownCircle } from 'react-bootstrap-icons';
import test_video_scoop from '../assets/test_video_scoop.gif';
import test_video_pour from '../assets/test_video_pour.gif';
import toto_paper from '../assets/toto_paper_preview.png';
import pouring_object from '../assets/pouring_objects.jpeg';
import scooping_object from '../assets/scooping_objects.jpeg';
import object_locations from '../assets/locations.jpg';
import ReactRoundedImage from 'react-rounded-image';

function Tasks() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const calculateImageDimensions = (originalWidth, originalHeight, targetWidth) => {
    const aspectRatio = originalWidth / originalHeight;
    const targetHeight = targetWidth / aspectRatio;

    return {
    imageWidth: targetWidth,
    imageHeight: targetHeight,
    };
  };

  return (
    <Container style={{ backgroundColor: '#323232', color: 'white', paddingTop: '100px' }}>
      <Row>
        <h2 className="custom-header"> TASKS</h2>
      </Row>
      <hr style={{ borderColor: 'white' }} />

      <Row>
        <Col sm={12} className="d-flex flex-column align-items-center">
          <h3 className="custom-header">Scooping</h3>
          <Row className="w-100">
            <Col sm={6} className="d-flex flex-column align-items-center">
              <ReactRoundedImage
                image={scooping_object}
                roundedColor="#323232"
                {...calculateImageDimensions(600, 300, windowSize.height * 0.5)}
                borderRadius="20"
              />
              <div className="text-start mt-3">
                <p>Train objects: blue plastic bowl, red metal bowl, black plastic container</p>
                <p>Train materials: coffee beans, almonds, mints</p>
                <p>Test objects: gray ceramic bowl, white styrofoam container</p>
                <p>Test material: mixed nuts</p>
              </div>
            </Col>
            <Col sm={6} className="d-flex justify-content-center">
              <ReactRoundedImage
                image={test_video_scoop}
                roundedColor="#323232"
                {...calculateImageDimensions(300, 300, windowSize.height * 0.4)}
                borderRadius="20"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr style={{ borderColor: 'white' }} />

      <Row>
        <Col sm={12} className="d-flex flex-column align-items-center">
          <h3 className="custom-header">Pouring</h3>
          <Row className="w-100">
            <Col sm={6} className="d-flex flex-column align-items-center">
              <ReactRoundedImage
                image={pouring_object}
                roundedColor="#323232"
                {...calculateImageDimensions(600, 300, windowSize.height * 0.5)}
                borderRadius="20"
              />
              <div className="text-start mt-3">
                <p>Train objects: clear plastic cup, pink plastic cup, red plastic cup, white paper cup</p>
                <p>Train materials: almonds, mints</p>
                <p>Test objects: green plastic cup, blue plastic cup</p>
                <p>Test material: Starburst candies</p>
              </div>
            </Col>
            <Col sm={6} className="d-flex justify-content-center">
              <ReactRoundedImage
                image={test_video_pour}
                roundedColor="#323232"
                {...calculateImageDimensions(300, 300, windowSize.height * 0.4)}
                borderRadius="20"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Tasks;
