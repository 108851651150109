import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parse from 'html-react-parser';
import victoria from '../assets/victoria.png';
import gaoyue from '../assets/gaoyue.png';
import mohan from '../assets/mohan.jpeg';
import sudeep from '../assets/sudeep.png';
import esther from '../assets/esther.png';
import jackie from '../assets/jackie.jpeg';
import marion from '../assets/marion.jpeg';
import paul from '../assets/paul.jpeg';
import abhinav from '../assets/abhinav.jpeg';
import chelsea from '../assets/chelsea.jpeg';
import aleksa from '../assets/aleksa.png';
import lerrel from '../assets/lerrel.jpeg';
import toto_dataset from '../assets/toto_dataset.gif';
import ReactRoundedImage from "react-rounded-image";

function Challenges() {
  const parse = require('html-react-parser');
  const organizers = [
    {
      name: 'Victoria Dean',
      image: victoria,
      institution: 'CMU',
      url: 'https://vdean.github.io/',
    },
    {
      name: 'Gaoyue Zhou',
      image: gaoyue,
      institution: 'CMU',
      url: 'https://gaoyuezhou.github.io/'
    },
    {
      name: 'Mohan Kumar Srirama',
      image: mohan,
      institution: 'CMU',
      url: 'https://www.mohansrirama.com/'
    },
    {
      name: 'Sudeep Dasari',
      image: sudeep,
      institution: 'CMU',
      url: 'https://sudeepdasari.github.io/'
    },
    {
      name: 'Esther Brown',
      image: esther,
      institution: 'Harvard',
      url: 'https://estherbrown1.github.io/'
    },
    {
      name: 'Marion Lepert',
      image: marion,
      institution: 'Stanford',
      url: 'https://www.linkedin.com/in/marion-lepert/',
    },
    {
      name: 'Jackie Kay',
      image: jackie,
      institution: 'Google DeepMind',
      url: 'https://www.linkedin.com/in/jackie-kay-75b2b154/'
    },
    {
      name: 'Paul Ruvolo',
      image: paul,
      institution: 'Olin College',
      url: 'http://occam.olin.edu/'
    },
    {
      name: 'Aleksandra Faust',
      image: aleksa,
      institution: 'Google DeepMind',
      url: 'https://www.afaust.info/'
    },
    {
      name: 'Lerrel Pinto',
      image: lerrel,
      institution: 'NYU',
      url: 'https://www.lerrelpinto.com/'
    },
    {
      name: 'Chelsea Finn',
      image: chelsea,
      institution: 'Stanford',
      url: 'https://ai.stanford.edu/~cbfinn/'
    },
    {
      name: 'Abhinav Gupta',
      image: abhinav,
      institution: 'CMU',
      url: 'http://www.cs.cmu.edu/~abhinavg/'
    },

    
    // Add more organizers here...
  ];

  const rows = Math.ceil(organizers.length / 4);

  const Organizer = ({ organizer }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center text">
        <Row>
          <a href={organizer.url}>
            <ReactRoundedImage
              image={organizer.image}
              roundedColor="#323232"
              imageWidth="250"
              imageHeight="250"
              borderRadius="125"
            />
          </a>
        </Row>
        <Row>
         <b>{organizer.name}</b>
        </Row>
        <Row>
         {organizer.institution}
        </Row>
      </div>
    );
  };
  
  

  return (
    <Container style={{ backgroundColor: '#323232', color: 'white', paddingTop: '100px', }}>
                                  <Row>
                                    <Col sm={2}>

                                    </Col>
                                <Col sm={8} style={{display:'flex', textAlign:'left'}}>
                              <tbody><tr>
                                <td>
                                  <p width="20%">

                                    {/* <br/>
                                    TOTO consists of two tabletop manipulations tasks, scooping and pouring, performed on a real robot. 
                                    The datasets of the two tasks can be downloaded <a href="https://drive.google.com/drive/folders/1JGPGjCqUP4nUOAxY3Fpx3PjUQ_loo7fc?usp=share_link"><u>here</u></a>.
                                    For detailed information about the datasets, please refer to the <a href="https://github.com/AGI-Labs/toto_benchmark/tree/release#toto-datasets"><u>dataset section</u></a> of our readme. */}

                                    <br/><br/>
                                    <h2 style={{fontFamily:'sans-serif', textAlign: 'center'}}>TOTO Benchmark Challenges</h2>
                                    We invite the community to participate in the TOTO benchmark challenges.
                                    Please follow the github tab for details on the challenges.
                                    You can reach us through email: <b>organizers@toto-benchmark.org</b> or
                                    join our <a href="https://join.slack.com/t/totocompetition/shared_invite/zt-23urfam11-B73S6tPX9iaTFu6YggxPnA">slack</a>!
                                  </p>
                                  </td></tr></tbody>
                                  {/* </table> */}
                                  </Col>
                                  </Row>
      <p>
        {/* <table align="center" width="800px"> */}
        {/* justify content center */}
        <hr style={{borderColor:'white'}}/>
        <Row>
          <h2>Important Dates</h2>

        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col sm={3}></Col>
          <Col sm={6} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
            <table style={{ margin: '20px', padding: '20px' }}>
              <thead>
                <tr>
                  <th style={{ width: '35%' }}></th>
                  <th style={{ width: '65%' }}></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>September 8 - Rolling</td>
                  <td>
                    Phase 1: Simulation Challenge <br/>
                    <b>Qualifying challenge for Phase 2.</b>
                  </td>
                </tr>
                <br />
                <tr>
                  <td>October 10 - November 6</td>
                  <td>
                    {/* Phase 1 complete: final submission deadline on August 8. */}
                    Phase 2: Real Robot Challenge
                  </td>
                </tr>
                <br />
                <tr>
                  <td>November 8</td>
                  <td>
                    {/* Phase 1 complete: final submission deadline on August 8. */}
                    Code and Method documentation deadline
                  </td>
                </tr>
                <br />
                <tr>
                  <td>December</td>
                  <td>
                    {/* Phase 1 complete: final submission deadline on August 8. */}
                    NeurIPS conference: competition track presentations, prizes announced
                  </td>
                </tr>
                <br />
              </tbody>
            </table>

          </Col>
        </Row>
        <hr style={{borderColor:'white'}}/>                            
        <br/>
        <Row>
          <h2>Rules</h2>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col sm={2}>

          </Col>
          <Col sm={8} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
          <ol style={{ textAlign: 'left'}}>
              <li>Participants may form teams of any size. An individual cannot be a member of multiple teams. Team members may not be added after the conclusion of Phase 1.</li>
              <li>Teams are limited to one submission account. If duplicate accounts are suspected, the team will be notified and the accounts may be disqualified or merged (if under 3 submissions across accounts).</li>
              <li>Pretraining representations is acceptable, but attempting to replicate the simulation task or hardware setup for online training is considered a violation of the offline nature of TOTO.</li>
              <li>Beta-testers are ineligible for prizes due to their advanced access. Similarly, close collaborators of any organizer are ineligible for participation (contact us if you are unsure).</li>
              <li>Winners will be determined by global ranking across tasks in the real-world Phase 2 evaluation.</li>
              <li>To be eligible for prizes, the winners must open-source their code with documentation and a description of the method. These must be provided by November 15, two weeks following the close of Phase 2. Organizers may verify any results using the provided code.</li>
              <li>Prizes should be split equally between members of a team.</li>
              <li>Winners will be asked to present during the NeurIPS competition track and be deanonymized.</li>
              <li>Organizers have the right to update the rules due to unforeseen circumstances to better serve the mission of the competition.</li>
              <li>Organizers have the right to disqualify participants who have violated the competition rules or the NeurIPS Code of Conduct.</li>
            </ol>
          </Col>
        </Row>
        <br/>
        <hr style={{borderColor:'white'}}/>
        <h2>Organizers</h2>

        <Row>
        {[...Array(rows)].map((_, rowIndex) => (
        <Row key={rowIndex}>
          {organizers.slice(rowIndex * 4, rowIndex * 4 + 4).map((organizer, index) => (
            <Col sm={3} style={{display:'flex', justifyContent:'center', flexDirection: 'column'}}>
            <Row style={{ justifyContent: 'center', textAlign: 'center' }}>
            
              <Organizer organizer={organizer} />
            </Row>
            </Col>
          ))}
          <Row>
          <br/>
          </Row>
        </Row>
        
      ))}
        </Row>
       
      </p>
    </Container>
  );
}

export default Challenges;
