import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import toto_dataset from '../assets/toto_dataset.gif';
import ReactRoundedImage from "react-rounded-image";
import {Checkmark} from 'react-checkmark';
import Button from 'react-bootstrap/Button';




function Home() {
    
    return (
        // style the container to have dark background
        <Container style={{backgroundColor:'#323232', color:'white', paddingTop:'100px', }}>

        <Row>
            {/* add img src toto_dataset */}
            {/* style img to 50% width */}
            {/* add center column */}
            {/* <Col xs={12} md={6}> */}
            {/* center content within col */}
            {/* <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}> */}

            <Col sm={6}>
                {/* use a pleasing font */}
                {/* <h1 style={{fontFamily:'Roboto', fontSize:'50px'}}>TOTO: A Dataset for Learning to Pour and Scoop</h1> */}

                <h1 class="custom-header">TOTO Benchmark</h1>
                {/* left align div */}

                <div className='d-flex' style={{textmargin:'auto', textAlign:'left', margin: '30'}}>
                <p>
                {/* make bold */}

                <b>Train Offline Test Online (TOTO)</b> is an online benchmark that provides:
                
                <ul>
                    <li>An open-source manipulation dataset</li>
                    <li>Access to shared robots for evaluation</li>    
                </ul>

                We release a dataset which consists of:
                {/* add checkmarks in next line */}

                <ul>
                    {/* add text inline with checkmark */}
                    <Row>
                        {/* add padding to col */}
                        <Col xs={1} style={{padding:'4px'}}>
                            <Checkmark size={16} color={'#00e89d'}/>
                        </Col>
                        {/* add new column center aligned with previous col */}
                        <Col xs={10} style={{textAlign:'left', paddingLeft:'8px'}}>
                        1,262,997 images of robot actions
                        </Col>
                    </Row>
                    <Row>
                        {/* add padding to col */}
                        <Col xs={1} style={{padding:'4px'}}>
                            <Checkmark size={16} color={'#00e89d'}/>
                        </Col>
                        {/* add zero padding to new column */}
                        <Col xs={10} style={{textAlign:'left', paddingLeft:'8px'}}>
                        {/* <Col xs={10} style={{textAlign:'left'}}> */}
                            1895 trajectories of scooping data
                        </Col>
                    </Row>
                    <Row>
                        {/* add padding to col */}
                        <Col xs={1} style={{padding:'4px'}}>
                            <Checkmark size={16} color={'#00e89d'}/>
                        </Col>
                        {/* add new column center aligned with previous col */}
                        <Col xs={10} style={{textAlign:'left', paddingLeft:'8px'}}>
                            1003 trajectories of pouring data
                        </Col>
                    </Row>
                    <Row>
                        {/* add padding to col */}
                        <Col xs={1} style={{padding:'4px'}}>
                            <Checkmark size={16} color={'#00e89d'}/>
                        </Col>
                        {/* add new column center aligned with previous col */}
                        <Col xs={10} style={{textAlign:'left', paddingLeft:'8px'}}>
                            15 different materials
                        </Col>
                    </Row>
                    <Row>
                        {/* add padding to col */}
                        <Col xs={1} style={{padding:'4px'}}>
                            <Checkmark size={16} color={'#00e89d'}/>
                        </Col>
                        {/* add new column center aligned with previous col */}
                        <Col xs={10} style={{textAlign:'left', paddingLeft:'8px'}}>
                            6 different object locations
                        </Col>
                    </Row>
                    
                </ul>
                
                We are organizing a competition at NeurIPS 2023. Click below to learn more!
                {/* add one line space */}
                <br/>
                <br/>
                {/* center button */}
                <p style={{display:'flex', justifyContent:'center'}}>
                <Button variant="success" href="#/challenges">Get Started</Button>{' '} 
                </p>
                </p>
            </div>
            </Col>
            <Col sm={6} style={{display:'flex', justifyContent:'center'}}>
                {/* <img src={toto_dataset} style={{width:'50%', height: '75%'}}/> */}
            
                <ReactRoundedImage image={toto_dataset} 
                        roundedColor="#323232" 
                        imageWidth="400" 
                        imageHeight="400" 
                        // roundedSize="13"
                        // hoverColor="#B01C33"
                        borderRadius="20"/>
            </Col>

            
        </Row>
        {/* Add a break line */}
        {/* <hr style={{borderColor:'white'}}/> */}

        {/* create a custom header tasks with two sections for scooping and pouring*/}

        {/* <hr style={{borderColor:'white'}}/>
        <Col sm={12} style={{display:'flex', justifyContent:'center'}}>
                <iframe width="852" height="480" src="https://www.youtube.com/embed/QCgZ-m1o_Pw" title="YouTube video player" 
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen>
        </iframe>
                </Col> */}
        
      </Container>
    );
  }
  
  export default Home;