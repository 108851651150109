import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function Paper() {
    return (
        <Container style={{ backgroundColor: '#323232', color: 'white', paddingTop: '100px', }}>
            <Row>
                <Col sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <iframe width="852" height="480" src="https://www.youtube.com/embed/QCgZ-m1o_Pw" title="YouTube video player"
                        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                    </iframe>
                </Col>
            </Row>
            <br />
            <Row>
                <Col sm={4}>

                </Col>
                <Col sm={4}>
                    <Button variant="success" href="https://arxiv.org/abs/2306.00942">Arxiv Link</Button>{' '}

                </Col>
            </Row>
            <br />
        </Container>
    );
}

export default Paper;