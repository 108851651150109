import React from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import tartan from '../assets/tartan_dog.jpeg';
import { motion, useScroll } from "framer-motion";



function TopNavBar() {

    /** this hook gets the scroll y-axis **/
    const { scrollY } = useScroll();
    /** this hook manages state **/
    const [hidden, setHidden] = React.useState(false);
  
    /** this onUpdate function will be called in the `scrollY.onChange` callback **/
    function update() {
      if (scrollY?.current < scrollY?.prev) {
        setHidden(false);
      } else if (scrollY?.current > 0 && scrollY?.current > scrollY?.prev) {
        setHidden(true);
      }
    }
  
    /** update the onChange callback to call for `update()` **/
    React.useEffect(() => {
      return scrollY.onChange(() => update());
    });
  
    /** add this const **/
    const variants = {
      /** this is the "visible" key and it's correlating styles **/
      visible: { opacity: 1, y: 0 },
      /** this is the "hidden" key and it's correlating styles **/
      hidden: { opacity: 0, y: -25 }
    };

  return (
    <motion.div /** the variants object needs to be passed into the motion component **/
      variants={variants}
      /** it's right here that we match our boolean state with these variant keys **/
      animate={hidden ? "hidden" : "visible"}
      /** I'm also going to add a custom easing curve and duration for the animation **/
      transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}>
  
    {/* set background color  */}
    {/* <Navbar bg="dark" variant="dark" expand="lg" style={{backgroundColor:'#292929'}}> */}
    <Navbar fixed="top" collapseOnSelect expand="lg" className="color-nav" variant="dark">
      <Container>
        <Navbar.Brand href="/" style={{flex:1, flexDirection:'column'}}>

            <img
              alt=""
              src={tartan}
              width="40"
              height="40"
              className="d-inline-block align-top"
            />{' '}
            {/* add text right below image */}
            {/* <div style={{flex:1, flexDirection:'column', textAlign:'center', color:'white', fontSize:'20px'}}>
                TOTO
            </div> */}
              
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="https://github.com/AGI-Labs/toto_benchmark">Github</Nav.Link>
            <Nav.Link href="#/paper">Paper</Nav.Link>
            {/* <Nav.Link href="https://drive.google.com/drive/folders/1JGPGjCqUP4nUOAxY3Fpx3PjUQ_loo7fc?usp=share_link">Data</Nav.Link> */}
            {/* <Nav.Link href="">Download</Nav.Link> */}
            <Nav.Link href="#/challenges">Competition</Nav.Link>
            <Nav.Link href="#/tasks">Tasks</Nav.Link>
            {/* Add a route to leaderboard */}
            <Nav.Link href="#/leaderboard">Leaderboard</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    </motion.div>
  );
}

export default TopNavBar;