import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const methodLinkFormatter = (cell, row) => {
  return (
    <a href={row.methodURL} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      {cell}
    </a>
  );
};

const columns = [{
  dataField: 'ranking',
  text: 'Ranking'
}, {
  dataField: 'method',
  text: 'Method',
  formatter: methodLinkFormatter},
  {
    dataField: 'reward',
    text: 'Average Reward'
  }
, {
  dataField: 'successrate',
  text: 'Success Rate %'
}];



const pouringData = [
    { ranking: 1, method: 'Behavior Cloning + Momentum Contrast (MOCO)', methodURL: 'https://arxiv.org/abs/1911.05722', reward: 7.42, successrate: 83.3 },
    { ranking: 2, method: 'Visual Imitation through Nearest Neighbors (VINN)', methodURL: 'https://arxiv.org/abs/2112.01511', reward: 7.89, successrate: 63.9 },
    { ranking: 3, method: 'Implicit Q-Learning (IQL)', methodURL: 'https://arxiv.org/abs/2110.06169', reward: 6.08, successrate: 47.2 },
    { ranking: 4, method: 'Decision Transformer (DT)', methodURL: 'https://arxiv.org/abs/2106.01345', reward: 2.83, successrate: 27.8 }
  ]

  const scoopingData = [
    { ranking: 1, method: 'Behavior Cloning + Momentum Contrast (MOCO)', methodURL: 'https://arxiv.org/abs/1911.05722', reward: 22.86, successrate: 72.2 },
    { ranking: 2, method: 'Visual Imitation through Nearest Neighbors (VINN)',  methodURL: 'https://arxiv.org/abs/2112.01511', reward: 21.75, successrate: 47.2 },
    { ranking: 3, method: 'Implicit Q-Learning (IQL)',  methodURL: 'https://arxiv.org/abs/2110.06169', reward: 9.86, successrate: 38.9 },
    { ranking: 4, method: 'Decision Transformer (DT)',  methodURL: 'https://arxiv.org/abs/2106.01345', reward: 0.0, successrate: 0.0 }
  ]

function Leaderboard() {
    return (
        // jusityfy-content-center
        <div>

        <div className="container" style={{backgroundColor:'#323232', color:'white', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:'100px'}}>
        <div>
            <h1 style={{fontFamily:'sans-serif'}}>Scooping</h1>
            {/* <BootstrapTable classes='table-dark' bootstrap4 keyField='ranking' data={ scoopingData } columns={ columns } pagination={ paginationFactory({sizePerPage: 5, hidePageListOnlyOnePage: true}) } /> */}
        <BootstrapTable classes='table-dark' bootstrap4 keyField='ranking' data={ scoopingData } columns={ columns } />
        </div>
        
        </div>

        <div className="container" style={{backgroundColor:'#323232', color:'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>


        <div>
            <h1 style={{fontFamily:'sans-serif'}}>Pouring</h1>
            {/* <BootstrapTable classes='table-dark' bootstrap4 keyField='ranking' data={ pouringData } columns={ columns } pagination={ paginationFactory({sizePerPage: 5, hidePageListOnlyOnePage: true}) } /> */}
        <BootstrapTable classes='table-dark' bootstrap4 keyField='ranking' data={ pouringData } columns={ columns } />
        </div>
       
        </div>
        </div>
    )
}

export default Leaderboard;