import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import { HashRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import Challenges from './routes/challenges';
import Home from './routes/home';
import Leaderboard from './routes/leaderboard';
import Tasks from './routes/tasks';
import TopNavBar from './routes/topnavbar';
import Paper from './routes/paper';


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StrictMode>
<HashRouter>
<Routes>
<Route path="/" element={<div className="home" style={{backgroundColor:'#323232'}}>
        <TopNavBar />
        {/* display home below topnavbar */}
        <Home />
      </div>} />
<Route path="challenges" element={<div className="home" style={{backgroundColor:'#323232'}}>
        <TopNavBar />
        <Challenges />
      </div>}/>
<Route path="paper" element={<div className="home" style={{backgroundColor:'#323232'}}>
        <TopNavBar />
        <Paper />
      </div>}/>
<Route path="tasks" element={<div className="home" style={{backgroundColor:'#323232'}}>
        <TopNavBar />
        <Tasks />
      </div>}/>
<Route path="leaderboard" element={<div className="home" style={{backgroundColor:'#323232'}}>
        <TopNavBar />
        <Leaderboard />
      </div>}/>
</Routes>
</HashRouter>
  </StrictMode>
);
